import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQ.css';
import HeadingText from '../UI/Controls/HeadingText/HeadingText';

const FAQ = ({ darkMode }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqData = [
        { question: "Is the software user-friendly and easy to navigate?", answer: "ezybill is very simple and easy to use software. Anyone with basic mobile/computer knowledge can use it." },
        { question: "Is the software compatible with mobile devices and tablets?", answer: "Yes. ezybill works on both Andriod & iOS mobiles & tablets" },
        { question: "Can I Generate E-Invoices and E-Way Bills through the software?", answer: "Yes. ezybill comes integrated with GST portal and generating E-Invoices and E-Way bills is as simple as just clicking on a button." },
        { question: "Can I Generate or File GSTR-1 Returns?", answer: "Yes. You can generate and download GSTR1 JSON file. ezybill also supports direct filing of GSTR-1." },
        { question: "How secure is my data with the software?", answer: "Rest assured, your data is the safest with us. We host our data within India and use enterprise grade hosting services from Microsoft® Azure." },
        { question: "Can I customize invoices with my company's logo and branding?", answer: "Yes. ezybill allows a range of invoice formats and also you can customize to suit according to your business requirements." },
        { question: "Does the software offer multi-user access and role-based permissions?", answer: "Yes. ezybill supports multi-user access and role-based permissions." },
        { question: "Can it handle multiple businesses under a single account?", answer: "Yes. With ezybill you can manage multiple business accounts." },
        { question: "How can I migrate my existing data to this new software?", answer: "Excel import for Customer, Supplier and Product data is easily possible in a few simple steps." },
        { question: "How do I back up and restore my data?", answer: "Automatic backups on cloud. Also, you can always download your data in CSV & PDF format." },
        { question: "Is there a free trial or demo available before purchase?", answer: "Yes. We offer a 15-Days Free Trial, where you can try the software for absolutely free of cost." },
        { question: "What kind of customer support is provided?", answer: "ezybill provides both on call and WhatsApp support. We also have a comprehensive video tutorials that can be accessed on our YouTube channel." },
        { question: "How often is the software updated to comply with new GST regulations?", answer: "ezybill is updated regularly to keep up with any GST regulation changes and updates usually are free of cost depending on the plan you have purchased." },
        { question: "Does the software support automatic GST calculations for transactions?", answer: "Yes. All GST calculations are done automatically." },
        { question: "Can I track my inventory and manage stock levels?", answer: "Yes. With ezybill you can track & manage inventory effortlessly." },
        { question: "Are there any hidden costs associated with the software?", answer: "No. You pay only for the subscription and nothing else. You get the features as per the subscription plan you have selected." },
        { question: "What kind of training and onboarding is provided for new users?", answer: "We provide free of cost training and on boarding assistance." },
        { question: "Are there any limitations on the number of transactions?", answer: "No. We have no limitations for number of invoices, quotations etc. However, there are caps on the number of E-Invoices, E-Way bills, GSTR-1 filing etc. based on the subscription plan." },
        { question: "Is there an offline mode available?", answer: "No. ezybill requires an active internet connection." }
    ];

    return (
        <section id="faq" className={`faq ${darkMode ? 'dark' : 'light'}`}>
            <Container>
                <Grid container justifyContent="center" className="section-title">
                    <Grid item xl={8} lg={8} textAlign="center">
                        <HeadingText mainHeading={"Frequently Asked Question"} darkMode={darkMode} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {faqData.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <Accordion
                                expanded={expanded === index}
                                onChange={handleChange(index)}
                                disableGutters={true}
                                style={{ margin: '0px 0' }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: '#ffff' }} />}
                                    aria-controls={`panel${index}bh-content`}
                                    id={`panel${index}bh-header`}
                                    style={{ padding: '4px 8px' }}
                                >
                                    <Typography variant="body1" style={{ fontSize: '1rem', lineHeight: '1.2rem', fontWeight: 'bold' }} className="accordion-header">
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ background: '#ff7f7f7', borderTop: '1px solid lightgray', padding: '13px 20px' }}>
                                    <Typography variant="body2" style={{ fontSize: '1rem' }} className='accordion-answer'>
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </section>
    );
};

export default FAQ;
