import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../UI/Controls/Buttons/PrimaryButton";
import './NavBar.css';
import Logo from '../../assets/images/navicon.png';
import { IconButton, Popover, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import SideBar from "../SideBar/SideBar";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import config from "../../config";


function NavBar({ darkMode, toggleDarkMode, scrollToSection, showOffer, setOpenDialog, setShowPartner, setShowOffer }) {
    const [activeLink, setActiveLink] = useState('/');
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const isTab = useMediaQuery('(max-width :1024px)')
    const [anchorEl, setAnchorEl] = useState(null);
    const [timer, setTimer] = useState(0);
    const [offerShown, setOfferShown] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const startDate = new Date(config.COUPON_START_DATE);
        const endDate = new Date(config.COUPON_END_DATE);
        const currentTime = Date.now();

        if (currentTime < startDate) {
            setOfferShown(false);
            setIsVisible(false);
        } else if (currentTime >= startDate && currentTime <= endDate) {
            setIsVisible(true)
            if ((showOffer && !offerShown)) {
                setOfferShown(true);
            }
        } else {
            setOfferShown(false);
            setIsVisible(false);
        }

    }, [showOffer, offerShown]);

    const handleLinkClick = (path) => {
        setActiveLink(path);
        setAnchorEl(null);
        scrollToSection(path);
        if (isMobile) setSidebarOpen(false);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const openPartnerHandler = () => {
        setShowPartner(true);
        setOpenDialog(true);
    }

    useEffect(() => {
        const initialTimerValue = 30 * 60 * 1000;

        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1000);
            }, 1000);

            return () => clearInterval(intervalId);
        } else {
            setTimer(0); // Reset timer when it reaches zero
        }
    }, [timer]);



    return (
        <>
            <header className={`navbar ${darkMode ? 'dark-mode' : 'light-mode'} ${isScrolled ? 'scrolled' : ''}`} style={{ top: isVisible && !isMobile ? '27px' : '0px' }}>
                <div className="navbar-content">
                    <div className="logo">
                        <img src={Logo} style={{ cursor: 'pointer' }} alt="ezybill" onClick={() => handleLinkClick('/')} />
                    </div>

                    {isMobile && offerShown && (
                        <div className="ribbon-sale" onClick={() => setShowOffer(true)}>
                            <span>Sale ending soon</span>
                        </div>
                    )}

                    {isMobile && (
                        <IconButton className="menu-toggle" onClick={toggleSidebar}>
                            <MenuIcon style={{ fontSize: '29px' }} />
                        </IconButton>
                    )}

                    <nav className="nav-links">
                        <Link className={activeLink === '/' ? 'active' : ''} onClick={() => handleLinkClick('/')}>
                            Home
                        </Link>
                        <Link className={activeLink === 'features' ? 'active' : ''} onClick={() => handleLinkClick('features')}>
                            Features
                        </Link>
                        <Link className={activeLink === 'pricing' ? 'active' : ''} onClick={() => handleLinkClick('pricing')}>
                            Pricing
                        </Link>
                        <Link className={activeLink === 'faq' ? 'active' : ''} onClick={() => handleLinkClick('faq')}>
                            FAQ
                        </Link>
                        <Link className={activeLink === 'contact' ? 'active' : ''} onClick={() => handleLinkClick('contact')}>
                            Contact
                        </Link>
                        <div>
                            <p onClick={handlePopoverOpen} className="dropdown-toggle">
                                More <KeyboardArrowDownIcon />
                            </p>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <div style={{ width: '150px' }}>
                                    <Typography onClick={() => window.open('https://www.youtube.com/@ezybillgstbillingsoftware4887', '_blank')} variant="body1" style={{ padding: '10px', cursor: 'pointer', color: '#0c4fe0' }}>
                                        Help Videos
                                    </Typography>
                                    <Typography onClick={() => window.open('https://www.ezybill.in/blogs/', '_blank')} variant="body1" style={{ padding: '10px', cursor: 'pointer', color: '#0c4fe0' }}>
                                        Blog
                                    </Typography>
                                    {
                                        isTab && (
                                            <>
                                                <Typography onClick={() => openPartnerHandler()} variant="body1" style={{ padding: '10px' }}>
                                                    Become a Partner
                                                </Typography>
                                            </>
                                        )
                                    }
                                </div>
                            </Popover>
                        </div>
                        {
                            !isTab && (
                                <>
                                    <Link onClick={() => openPartnerHandler()} style={{ color: '#0C4FE0', fontWeight: 'bold' }}>
                                        Become a Partner
                                    </Link>
                                </>
                            )
                        }
                    </nav>
                    {!isMobile && (
                        <div className="right-actions">

                            <a href="https://www.gst.ezybill.in/" className="action-link" target="_blank" rel="noopener noreferrer">
                                Login
                            </a>

                            <PrimaryButton darkMode={darkMode} handleClick={handleOpenDialog}>Request Demo</PrimaryButton>
                            {/*                             <ThemeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
 */}                        </div>
                    )}
                </div>
            </header>
            <SideBar
                isOpen={isSidebarOpen}
                toggleSidebar={toggleSidebar}
                darkMode={darkMode}
                handleLinkClick={handleLinkClick}
                activeLink={activeLink}
                toggleDarkMode={toggleDarkMode}
                setOpenDialog={setOpenDialog}
            />
        </>
    );
}

export default NavBar;
