import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './InvoiceImages.css';

// Import images
import classicCompactInvoice from '../../assets/images/Invoice/classic_compact_invoice.jpg';
import comfort_with_logo_invoice from '../../assets/images/Invoice/comfort_with_logo_invoice.jpg';
import elegent_with_logo from '../../assets/images/Invoice/elegent_with_logo.jpg';
import invoice_classic_with_logo from '../../assets/images/Invoice/invoice_classic_with_logo.jpg';
import modern_retail_with_logo from '../../assets/images/Invoice/modern_retail_with_logo.jpg';
import modern_with_logo from '../../assets/images/Invoice/modern_with_logo.jpg';
import HeadingText from '../UI/Controls/HeadingText/HeadingText';
import Professional from '../../assets/images/Invoice/Professional.jpg';
import ProfessionalGreen from '../../assets/images/Invoice/Professional_2.jpg';
import retail_pos_thermal_printer_80mm from '../../assets/images/Invoice/POS.jpg';


const sliderImages = [
    { url: Professional, isPOS: false },
    { url: ProfessionalGreen, isPOS: false },
    { url: retail_pos_thermal_printer_80mm, isPOS: false },
    { url: classicCompactInvoice, isPOS: false },
    { url: comfort_with_logo_invoice, isPOS: false },
    { url: elegent_with_logo, isPOS: false },
    { url: invoice_classic_with_logo, isPOS: false },
    { url: modern_retail_with_logo, isPOS: false },
    { url: modern_with_logo, isPOS: false },
];

const InvoiceImages = ({ darkMode }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Default for larger screens
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: (dots) => (
            <div className="dots-container">
                <ul style={{ margin: "0px" }}>{dots}</ul>
            </div>
        ),
        customPaging: (i) => (
            <div className="dot-button">
                {i + 1}
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    appendDots: undefined,
                    customPaging: undefined,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    appendDots: undefined,
                    customPaging: undefined,
                },
            },
        ],
    };


    return (
        <div className="slider-container">
            <HeadingText mainHeading={"Professional & Customizable Invoice Formats 🚀"} darkMode={darkMode} />
            <Slider {...settings}>
                {sliderImages.map((image, index) => (
                    <div key={index} className="slider-item">
                        <img
                            src={image.url}
                            alt={`Invoice ${index + 1}`}
                            className={image.isPOS ? "slider-image-pos" : "slider-image"}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default InvoiceImages;
