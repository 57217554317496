import React, { useEffect, useState } from 'react';
import './LandingPage.css';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import PrimaryButton from '../UI/Controls/Buttons/PrimaryButton';
import NewsletterButton from '../UI/Controls/Buttons/NewsletterButton';
import Landing from '../../assets/images/landing.gif';

export const LandingPage = ({ darkMode, setOpenDialog, setMobileNumber }) => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [showFirecrackers, setShowFirecrackers] = useState(true);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowBanner(true);
        }, 5000); // Show banner after 5 seconds

        return () => clearTimeout(timer); // Clear the timer on unmount
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => setShowFirecrackers(false), 5000);
        return () => clearTimeout(timer); // Clean up the timer
    }, []);

    return (
        <section id="home">
            <div className={`landing-page ${darkMode ? 'dark' : 'light'}`}>
                <Grid container style={{ marginTop: isMobile ? '70px' : '90px' }} spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={7} lg={6} className="text-section">
                        <Typography variant="h4" className="main-title">
                            India's Most <span style={{ color: 'red' }}>❤</span> GST Accounting Software!
                        </Typography>
                        {isMobile && (
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <img src={Landing} alt="Description" style={{ width: '90%', height: 'auto', border: '2px solid #E4ECFA', borderRadius: '10px', padding: '8px', margin: '8px' }} />
                            </Grid>
                        )}
                        <p className="hosted-text">
                            Hosted on <strong>Microsoft® Azure</strong> and built using the latest technology stack, ezybill is an <strong>Enterprise-Grade Application</strong> that is <strong>Safe, Secure, and Cost-Effective.</strong>
                        </p>
                        <Grid container spacing={2} className="button-group" style={{ textAlign: 'center' }}>
                            <Grid item>
                                <NewsletterButton setOpenDialog={setOpenDialog} setMobileNumber={setMobileNumber} />
                            </Grid>
                            <Grid item>
                                <PrimaryButton darkMode={darkMode} external={true}>Start Free Trial</PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isMobile && (
                        <Grid item xs={12} sm={5} md={5} lg={6} display="flex" justifyContent="center" alignItems="center">
                            <img src={Landing} alt="Description" style={{ width: '90%', height: 'auto', border: '2px solid #E4ECFA', borderRadius: '10px', padding: '8px', margin: '8px' }} />
                        </Grid>
                    )}
                </Grid>
            </div>
        </section>
    );
};
