import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Box, Rating, Typography, useMediaQuery, Avatar } from "@mui/material";
import ButtonGroup from "../UI/Controls/Buttons/ButtonGroup";
import HeadingText from "../UI/Controls/HeadingText/HeadingText";

const data = [
    {
        id: 0,
        title: 'Really very easy and simple to use. Thanks, great job!',
        description:
            'We have been going through the very useful How-to/Help videos on your YouTube channel and are very impressed. You have the right mix of features that we needed. Thanks, you people have done a great job and very sound software!',
        name: 'Rajaram Yedve',
        designation: 'EQUITECH, Goa',
        review: 5,
    },
    {
        id: 1,
        title: 'Saves our time',
        description:
            'Not only our problems of hand written bills was solved, it also saves our time by giving quick details about payments to made, received etc.',
        name: 'Murali Kalburgi',
        designation: 'Abhishek Electricals, Bengaluru',
        review: 4,
    },
    {
        id: 2,
        title: 'Best decision we took',
        description:
            'Tried Zoho and Quickbooks before we switched to ezybill and it was one of the best decisions we took. Have been using it since 2019 for our B2B business and its just perfect! Simple to use and great value for money compared to others out there in the market.',
        name: 'Rahul Pramanik',
        designation: 'Round Two Ventures LLP, Kolkata',
        review: 5,
    },
    {
        id: 3,
        title: 'Support has been excellent',
        description:
            'From the day we have purchased this software, the support has been excellent and also we were able to request some custom changes to align with our business. Great team!.',
        name: 'Nikhil',
        designation: 'Marudhar Electricals, Bengaluru.',
        review: 5,
    },
    {
        id: 4,
        title: 'Could request for some customization',
        description:
            'Great value for money, we even could request for some customization and updates. Our billing needs have been taken care of well.',
        name: 'Pradeep',
        designation: 'SRECA COSMETICS PVT LTD, Bengaluru.',
        review: 4,
    },
];

export default function Testimonial({ darkMode }) {
    const isMobileMode = useMediaQuery('(max-width: 768px)');

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const carouselParams = {
        additionalTransfrom: 0,
        arrows: false,
        autoPlaySpeed: 3000,
        centerMode: false,
        className: "",
        containerClass: "carousel-container",
        customButtonGroup: isMobileMode ? <ButtonGroup /> : null,
        dotListClass: "",
        draggable: isMobileMode ? true : false,
        focusOnSelect: false,
        infinite: true,
        itemClass: "carousel-item",
        keyBoardControl: true,
        minimumTouchDrag: 80,
        renderButtonGroupOutside: true,
        renderDotsOutside: false,
        responsive: responsive,
        showDots: false,
        sliderClass: "",
        slidesToSlide: 1,
    };

    return (
        <section id='testimonial'>
            <HeadingText mainHeading={"What Our Customer say"} darkMode={darkMode} />
            <Box
                sx={{
                    padding: '50px 30px',
                    textAlign: 'left',
                    marginTop: '-47px'
                }}
            >
                <Carousel autoPlay {...carouselParams}>
                    {data.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                backgroundColor: darkMode ? '#333333' : 'white',
                                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                padding: '15px',
                                margin: '10px 10px',
                                height: isMobileMode ? 'auto' : '350px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                transition: 'transform 0.3s ease',
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 50,
                                    height: 50,
                                    marginBottom: '10px',
                                    backgroundColor: '#f57c00',
                                    fontSize: '1.5rem',
                                }}
                            >
                                {item.name[0]}
                            </Avatar>
                            <Rating value={item.review} readOnly sx={{ marginBottom: 1 }} />
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 'bold',
                                    color: darkMode ? 'white' : '#333',
                                    marginBottom: '5px',
                                    fontSize: '1rem',
                                    textAlign: 'center'
                                }}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: darkMode ? 'white' : '#555',
                                    textAlign: 'center',
                                    marginBottom: '15px',
                                    fontSize: '0.85rem',
                                }}
                            >
                                {item.description}
                            </Typography>
                            <Box
                                sx={{
                                    paddingTop: '5px',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: darkMode ? 'white' : '#333',
                                        fontSize: '0.9rem',
                                    }}
                                >
                                    {item.name}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: darkMode ? 'white' : '#777',
                                        fontSize: '0.75rem',
                                    }}
                                >
                                    {item.designation}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </section>
    );
}
