import React from 'react';
import './Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ezybillLogo from '../../assets/images/navicon.png';
import PhoneIcon from '@mui/icons-material/Phone';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import EmailIcon from '@mui/icons-material/Email';
import { useMediaQuery, useTheme } from '@mui/material';

export default function Footer({ scrollToSection }) {
    const theme = useTheme();
    return (
        <>
            <footer className="footer-distributed">

                <div className="footer-left">
                    <img src={ezybillLogo} alt='ezybill' width={"40%"} />

                    <p className="footer-links">
                        <a rel='noreferrer' href className="link-1" onClick={() => scrollToSection('/')} style={{ cursor: 'pointer' }}>Home</a>

                        <a rel='noreferrer' href onClick={() => scrollToSection('features')} style={{ cursor: 'pointer' }}>Features</a>

                        <a rel='noreferrer' href onClick={() => scrollToSection('pricing')} style={{ cursor: 'pointer' }}>Pricing</a>

                        <a rel='noreferrer' href onClick={() => scrollToSection('faq')} style={{ cursor: 'pointer' }}>FAQ</a>

                        <a rel='noreferrer' href onClick={() => scrollToSection('contact')} style={{ cursor: 'pointer' }}>Contact</a>

                        <a rel='noreferrer' href onClick={() => scrollToSection('partner')} style={{ cursor: 'pointer' }}>Become a Partner</a>

                        <a rel='noreferrer' href onClick={() => window.open('https://www.youtube.com/@ezybillgstbillingsoftware4887', '_blank')} style={{ cursor: 'pointer' }}>Help Videos</a>

                        <a rel='noreferrer' href onClick={() => window.open('https://www.ezybill.in/blogs/', '_blank')} style={{ cursor: 'pointer' }}>Blog</a>

                    </p>
                </div>

                <div className="footer-center">

                    <div>
                        <i><AddLocationAltIcon /></i>
                        <p><span>#39, 8th Main Road, Vasanth Nagar</span>Bengaluru, Karnataka 560052</p>
                    </div>

                    <div>
                        <i><PhoneIcon /></i>
                        <p>+91 90190 59713 or 74834 63220</p>
                    </div>

                    <div>
                        <i><EmailIcon /></i>
                        <p><a href="mailto:support@company.com">support@ezybill.in</a></p>
                    </div>

                </div>

                <div className="footer-right">

                    <p className="footer-company-about">
                        <span>www.ezybill.in</span>
                        Generate E-Invoices, Create E-Way Bills in one click, Create professional GST invoices, pro-forma invoice, quotaions, delivery challans, sales orders, purchase orders,  manage perfect inventory, manage receivables & payables and much more from anywhere, anytime. Hosted on Microsoft® Azure, ezybill is an enterprise grade application - yet cost effective, simple, safe and secure.
                    </p>

                    <div className="footer-icons">

                        <a href="https://www.facebook.com/groups/4564204143697979/?ref=share" target="_blank" rel="noreferrer">
                            <FacebookIcon style={{ marginTop: '5px' }} />
                        </a>

                        <a href="https://www.instagram.com/ezybill.in/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                            <InstagramIcon style={{ marginTop: '5px' }} />
                        </a>

                        <a href="https://www.youtube.com/channel/UChxUStbZbK8zor-dgVXxd-g" target="_blank" rel="noreferrer">
                            <YouTubeIcon style={{ marginTop: '5px' }} />
                        </a>

                    </div>
                </div>
                {
                    <p className="footer-company-name" style={{ textAlign: 'center' }}>Copyright © 2024 EmerSys LLP – All Rights Reserved.</p>
                }
            </footer>
        </>
    );
}