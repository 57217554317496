import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    ButtonBase,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './PricingDialog.css';
import config from '../../../../../config';

const tableData = [
    {
        feature: 'Multi User',
        silver: '❌',
        gold: '❌',
        platinum: '✅',
    },
    {
        feature: 'User Role Management',
        silver: '❌',
        gold: '❌ ',
        platinum: '✅',
    },
    {
        feature: 'E-Way JSON Download',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'One Click E-Way',
        silver: '❌',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'E-Invoicing (Unlimited)',
        silver: '❌',
        gold: '❌',
        platinum: '✅',
    },
    {
        feature: 'Inventory Management',
        silver: '❌',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Item Grouping',
        silver: '❌',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'UoM Conversion',
        silver: '❌',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Batch/Serial Tracking',
        silver: '❌',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Stock Adjustments',
        silver: '❌',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Quotation/Pro-forma',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Delivery Challan',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Sales Orders',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Sales Invoice',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Purchase Bills',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Receivables',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Payables',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Batch Payments',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Credit/Debit Notes',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Manage Refunds',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Expense Management',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Email Alerts',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Bank Accounts',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Cheque Printing',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Customer/Supplier Ledger',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Reports',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Dashboard',
        silver: '✅',
        gold: '✅',
        platinum: '✅',
    },
    {
        feature: 'Support',
        silver: '✅ Demo/Onboarding *',
        gold: '✅ WhatsApp Support *',
        platinum: '✅ Dedicated Support *',
    },
];

const PricingDailog = ({ open, onClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = () => {
        window.open(`https://www.gst.ezybill.in/upgradenow?cc=${config.COUPON_CODE}`, '_blank')
    }

    const getPlatinumPrice = () => {
        window.open('https://api.whatsapp.com/send/?phone=917760204876&text=Get Platinum Pricing&type=phone_number&app_absent=0', '_blank')
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" fullScreen={fullScreen}>
            <DialogTitle style={{ padding: '8px 16px' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant='h6'>Compare Features</Typography>
                    <ButtonBase onClick={onClose} style={{ fontSize: 18, color: 'red' }}>✖</ButtonBase>
                </Box>
            </DialogTitle>

            <DialogContent style={{ padding: '8px', paddingBottom: 20, overflow: 'hidden' }}>
                <TableContainer className="custom-table-container" style={{ maxHeight: fullScreen ? '72vh' : 360, border: '1px solid lightsteelblue' }} stickyHeader>
                    {!fullScreen && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#e8f4ff', position: 'sticky', top: '-2px', zIndex: 1 }}>
                                        <Typography variant="subtitle1">Features</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ backgroundColor: '#e8f4ff', position: 'sticky', top: '-2px', zIndex: 1 }}>
                                        <Typography variant="subtitle1">Silver</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ backgroundColor: '#e8f4ff', position: 'sticky', top: '-2px', zIndex: 1 }}>
                                        <Typography variant="subtitle1">Gold</Typography>
                                    </TableCell>
                                    <TableCell align="center" style={{ backgroundColor: '#e8f4ff', position: 'sticky', top: '-2px', zIndex: 1 }}>
                                        <Typography variant="subtitle1">Platinum</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.feature}</TableCell>
                                        <TableCell align="center">{row.silver}</TableCell>
                                        <TableCell align="center">{row.gold}</TableCell>
                                        <TableCell align="center">{row.platinum}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}

                    {/* Mobile-friendly structure */}
                    {fullScreen && (
                        <Box className="mobile-table">
                            {/* Mobile Headings */}
                            <Box className="mobile-table-row" sx={{ display: 'flex', padding: '8px', background: '#e8f4ff', borderBottom: '1px solid lightgray', fontWeight: 'bold' }}>
                                <Box sx={{ flex: 1 }}>Features</Box>
                                <Box sx={{ flex: 1, textAlign: 'center' }}>Silver</Box>
                                <Box sx={{ flex: 1, textAlign: 'center' }}>Gold</Box>
                                <Box sx={{ flex: 1, textAlign: 'center' }}>Platinum</Box>
                            </Box>

                            {/* Mobile Data Rows */}
                            {tableData.map((row, index) => (
                                <Box key={index} className="mobile-table-row" sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px', borderBottom: '1px solid lightgray', fontSize: fullScreen ? '12px' : null }}>
                                    <Box sx={{ flex: 1 }}>{row.feature}</Box>
                                    <Box sx={{ flex: 1, textAlign: 'center' }}>{row.silver}</Box>
                                    <Box sx={{ flex: 1, textAlign: 'center' }}>{row.gold}</Box>
                                    <Box sx={{ flex: 1, textAlign: 'center' }}>{row.platinum}</Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                </TableContainer>
            </DialogContent>

            <DialogActions style={{ justifyContent: fullScreen ? 'space-around' : 'right', backgroundColor: '#e8f4ff', padding: '16px' }}>
                <Box display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        className="buttonCompare silver"
                        onClick={handleClick}
                    >
                        Buy Silver
                    </Button>
                    <Button
                        variant="contained"
                        className="buttonCompare gold"
                        onClick={handleClick}
                    >
                        Buy Gold
                    </Button>
                    <Button
                        variant="contained"
                        className="buttonCompare platinum"
                        onClick={getPlatinumPrice}
                    >
                        Get Platinum
                    </Button>
                </Box>
            </DialogActions>

        </Dialog>
    );
};

export default PricingDailog;
