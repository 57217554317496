import React, { useState, useEffect } from 'react';
import './OfferNotification.css';
import config from './../../config';

const OfferNotification = ({ darkMode }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [timer, setTimer] = useState(0); // Initialize the timer state with 0
    const [messageParts, setMessageParts] = useState({
        couponMessage: false,
        timerMessage: false,
        saleMessage: false,
        claimMessage: false,
    });

    useEffect(() => {
        const startDate = new Date(config.COUPON_START_DATE);
        const endDate = new Date(config.COUPON_END_DATE);
        const currentTime = Date.now();

        if (currentTime < startDate) {
            setIsVisible(false);
            setTimer(0);
        } else if (currentTime >= startDate && currentTime <= endDate) {
            setIsVisible(true);
            const differenceInMilliseconds = endDate.getTime() - currentTime;
            setTimer(differenceInMilliseconds);
        } else {
            setIsVisible(false);
            setTimer(0);
        }
    }, []);



    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1000);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timer]);

    useEffect(() => {
        const messageTimers = [
            setTimeout(() => setMessageParts(prev => ({ ...prev, couponMessage: true })), 3000),
            setTimeout(() => setMessageParts(prev => ({ ...prev, timerMessage: true })), 3000),
            setTimeout(() => setMessageParts(prev => ({ ...prev, saleMessage: true })), 3000),
            setTimeout(() => setMessageParts(prev => ({ ...prev, claimMessage: true })), 3000),
        ];

        return () => messageTimers.forEach(id => clearTimeout(id));
    }, []);

    const formatTimer = () => {
        const totalSeconds = Math.floor(timer / 1000);
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return days > 0
            ? `${days} Day${days > 1 ? 's' : ''} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
            : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        isVisible && (
            <div className={`notification-banner ${darkMode ? 'dark-mode' : 'light-mode'}`} style={{ top: 0 }}>
                <span className="notification-message">
                    {messageParts.couponMessage && (
                        <span style={{ fontWeight: 400 }}>
                            Get Additional <span style={{ fontWeight: 600 }}>{config.OFFER_DESCRIPTION}</span>, Use coupon: {config.COUPON_CODE}&nbsp;&nbsp;
                        </span>
                    )}
                </span>
                <span className="apply-now" style={{ color: 'red', fontWeight: 'bold' }}>
                    {messageParts.saleMessage && <>SALE!!! &nbsp;&nbsp;</>}
                </span>
                <a
                    href={`https://www.gst.ezybill.in/upgradenow?cc=${config.COUPON_CODE}`}
                    target="_blank"
                    rel="noreferrer"
                    className="apply-now"
                >
                    {messageParts.claimMessage && <>Claim Now! →</>}
                </a>
                <span className="timer">
                    {messageParts.timerMessage && (
                        <>
                            {formatTimer()} remaining
                        </>
                    )}
                </span>
            </div>
        )
    );
};

export default OfferNotification;
