// src/App.js
import './App.css';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import NavBar from './components/Navbar/NavBar';
import OfferNotification from './components/OfferNotification/OfferNotification';
import MobileOffer from './components/OfferNotification/MobileOffer';
import { LandingPage } from './components/LandingPage/LandingPage';
import Dialog from './components/UI/Controls/Dialog/Dialog';
import Banner from './components/Banner/Banner';
import { Features } from './components/Features/FeatureComponets';
import InvoiceImages from './components/InvoiceFormat/InvoiceImages';
import BadgeGrid from './components/Badge/BadgeGrid';
import Pricing from './components/Pricing/Pricing';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import Testimonial from './components/Testimonial/Testimonial';
import Contact from './components/Contact/Contact';
import ChatBoatContainer from './components/LiveChat/ChatBoatContainer';
import config from './config';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [mobileNumber, setMobileNumber] = useState();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [showOffer, setShowOffer] = useState(false);
  const [openCount, setOpenCount] = useState(0);
  const [showPartner, setShowPartner] = useState(null);

  const featuresRef = useRef(null);
  const homeRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);
  const pricingRef = useRef(null);

  useEffect(() => {
    const startDate = new Date(config.COUPON_START_DATE);
    const endDate = new Date(config.COUPON_END_DATE);
    const currentTime = Date.now();

    if (currentTime < startDate) {
      setShowOffer(false);
    } else if (currentTime >= startDate && currentTime <= endDate) {
      setTimeout(() => {
        setShowOffer(true);
      }, 5000);
    } else {
      setShowOffer(false);
    }
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode]
  );

  const onCloseOffer = () => {
    setShowOffer(false);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (entry.isIntersecting && openCount < 2) {
          setOpenDialog(true);
          setOpenCount((prevCount) => prevCount + 1);
        }
      },
      {
        threshold: 0.1,
      }
    );

    const currentRef = featuresRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    } else {
      console.log('featuresRef.current is null');
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [openCount]);


  const scrollToSection = (section) => {
    if (section === '/' && homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'features' && featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'faq' && faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'contact' && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'pricing' && pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'partner') {
      setShowPartner(true);
      setOpenDialog(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>

      <Router>

        <CssBaseline />

        <Dialog open={openDialog} onClose={() => { setOpenDialog(false); setShowPartner(false) }} mobileNumber={mobileNumber} darkMode={darkMode} showPartner={showPartner} />

        {!isMobile && <OfferNotification darkMode={darkMode} onClose={onCloseOffer} />}
        <NavBar darkMode={darkMode} showOffer={showOffer} toggleDarkMode={toggleDarkMode} setOpenDialog={setOpenDialog} scrollToSection={scrollToSection} setShowPartner={setShowPartner} setShowOffer={setShowOffer} />

        <div ref={homeRef}>
          <LandingPage darkMode={darkMode} setShowOffer={setShowOffer} setOpenDialog={setOpenDialog} showOffer={showOffer} setMobileNumber={setMobileNumber} />
        </div>

        {isMobile && showOffer && <MobileOffer open={showOffer} onClose={onCloseOffer} darkMode={darkMode} />}

        <BadgeGrid darkMode={darkMode} />
        <Banner darkMode={darkMode} />

        <div ref={featuresRef}>
          <Features darkMode={darkMode} />
        </div>

        <InvoiceImages />

        <div ref={pricingRef}>
          <Pricing darkMode={darkMode} />
        </div>
        <ChatBoatContainer scrollToSection={scrollToSection} />

        <Testimonial darkMode={darkMode} />

        <div ref={faqRef}>
          <FAQ darkMode={darkMode} />
        </div>

        <div ref={contactRef}>
          <Contact darkMode={darkMode} />
        </div>
        <Footer scrollToSection={scrollToSection} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
