// New feature data
export const Data = [
    {
        id: 1,
        iconName: 'MultipleLocation',
        title: 'Anywhere Anytime Access',
        description: 'Access from Anywhere Anytime. Unlimited Device logins.',
        colorTheme: '#F09648',
        videoLink: ''

    },
    {
        id: 2,
        iconName: 'EInvoice',
        title: 'E-Invoicing Made Easy',
        description: 'Just one click to directly submit your invoices and generate IRN and QR Code.',
        colorTheme: '#24ADF3',
        videoLink: "https://www.youtube.com/embed/GZGhPW7Sssg?si=8KC6LuyI91Y4mtWo"
    },
    {
        id: 3,
        iconName: 'EWayBill',
        title: 'Generate E-Way Bills',
        description: 'Save time with effortless E-Way Bill Integration. Even generate E-Way for Delivery Challans and SEZ customers.',
        colorTheme: '#F09648',
        videoLink: "https://www.youtube.com/embed/xQWZxr4t6HU?si=yoEn71rt5oGTs7cG"
    },
    {
        id: 4,
        iconName: 'Share',
        title: 'Share via WhastApp/SMS/Email',
        description: 'Manage and add multiple Businesses under a single login.',
        colorTheme: '#F09648',
        videoLink: ''

    },
    {
        id: 5,
        iconName: 'MultiUser',
        title: 'Multi User Management',
        description: 'Manage and add multiple users. Manage User Roles and Permissions at module level.',
        colorTheme: '#F09648',
        videoLink: 'https://www.youtube.com/embed/Ck345X4Gy6Y?si=lsmPWp4fWSRtbBDb'

    },
    {
        id: 6,
        iconName: 'MultiCompany',
        title: 'Manage Multiple Businesses',
        description: 'Manage and add multiple Businesses under a single login.',
        colorTheme: '#F09648',
        videoLink: 'https://www.youtube.com/embed/Ck345X4Gy6Y?si=lsmPWp4fWSRtbBDb'

    },
    {
        id: 7,
        iconName: 'Batch',
        title: 'Batch/Serial No. Tracking',
        description: 'Track items with either batches with manufacturing date and expiry date or with Serial Numbers.',
        colorTheme: '#FA578B',
        videoLink: 'https://www.youtube.com/embed/hk2_1s5ffG4?si=KQ6U-X8g9wDX-JdP'
    },   
    {
        id: 8,
        iconName: 'Unit',
        title: 'Unit of Measurement Conversion',
        description: 'Convert an unit of measurement by providing a user defined Conversion factor.',
        colorTheme: '#24ADF3',
        videoLink: 'https://www.youtube.com/embed/qJNQP25aaqA?si=CyK37t39vJwNjEJz'
    },
    {
        id: 9,
        iconName: 'ItemGrouping',
        title: 'Item Grouping',
        description: 'Group similar items and create multiple items with different attributes like size, color etc. in one go.',
        colorTheme: '#1CBD7E',
        videoLink: 'https://www.youtube.com/embed/ZdjvPEqkBG8?si=OSNip84OyzCzygyp'
    },

    {
        id: 10,
        iconName: 'CustomerRate',
        title: 'Set Customer Wise Rates',
        description: 'With ezybill, you can choose to use the last sale price for a given Customer.',
        colorTheme: '#1CBD7E',
        videoLink: 'https://www.youtube.com/embed/rUGZTAA8Qdg?si=s3SrOAOK9ABeZz-U'
    },
    {
        id: 11,
        iconName: 'Convert',
        title: 'One Click to Convert Vouchers',
        description: 'Convert existing Quotations, Delivery Challans/ Purchase Orders into Sales Invoice/Purchase Bill.',
        colorTheme: '#FA578B',
        videoLink: 'https://www.youtube.com/embed/Mj1QmBwACh4?si=r-CNpMz-IGAoFlRv'
    },
    {
        id: 12,
        iconName: 'Secure',
        title: 'Secure & Enterprise Grade Hosting',
        description: 'Hosted on enterprise grade Microsoft© Azure, ensuring uptime and security.',
        colorTheme: '#1CBD7E',
        videoLink: 'https://www.youtube.com/embed/GTioH4ER4L8?si=08pvoqModwIDICW_'
    }
];
