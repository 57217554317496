import React from "react";
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './SideBar.css';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PricingIcon from '@mui/icons-material/MonetizationOn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BlogIcon from '@mui/icons-material/Description';
import SignInIcon from '@mui/icons-material/Login';
import RequestDemoIcon from '@mui/icons-material/CalendarToday';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

const SideBar = ({ isOpen, toggleSidebar, darkMode, toggleDarkMode, setOpenDialog }) => {

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        toggleSidebar();
    };

    return (
        <Drawer
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}
            variant="temporary"
            PaperProps={{
                style: {
                    width: '70vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                },
            }}
        >
            <div className={`sidebar ${darkMode ? 'dark-mode' : 'light-mode'}`}>
                <div className="close-icon" onClick={toggleSidebar}>
                    <CloseIcon fontSize="small" style={{ fontSize: '27px' }} />
                </div>
                <List style={{ paddingTop: '17px' }}>
                    <ListItem button onClick={() => scrollToSection('home')}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => scrollToSection('features')}>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="Features" />
                    </ListItem>
                    <ListItem button onClick={() => scrollToSection('faq')}>
                        <ListItemIcon>
                            <LiveHelpIcon />
                        </ListItemIcon>
                        <ListItemText primary="FAQ" />
                    </ListItem>
                    <ListItem button onClick={() => scrollToSection('contact')}>
                        <ListItemIcon>
                            <ContactMailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Contact" />
                    </ListItem>
                    <ListItem button onClick={() => scrollToSection('pricing')}>
                        <ListItemIcon>
                            <PricingIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pricing" />
                    </ListItem>
                    <ListItem button onClick={() => { window.open('https://www.youtube.com/@ezybillgstbillingsoftware4887', '_blank'); toggleSidebar(); }}>
                        <ListItemIcon>
                            <YouTubeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Help Video" />
                    </ListItem>
                    <ListItem button onClick={() => { window.open('https://www.ezybill.in/blogs/', '_blank'); toggleSidebar(); }}>
                        <ListItemIcon>
                            <BlogIcon />
                        </ListItemIcon>
                        <ListItemText primary="Blog" />
                    </ListItem>
                    <ListItem button component="a" href="https://www.gst.ezybill.in/" target="_blank" rel="noopener noreferrer" onClick={toggleSidebar}>
                        <ListItemIcon>
                            <SignInIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log in" />
                    </ListItem>
                    <ListItem button onClick={() => { setOpenDialog(true); toggleSidebar() }}>
                        <ListItemIcon>
                            <RequestDemoIcon />
                        </ListItemIcon>
                        <ListItemText primary="Request Demo" />
                    </ListItem>
                </List>
                <div className="bottom-section">
                    <div className="social-icons">
                        <IconButton onClick={() => window.open('https://facebook.com', '_blank')}>
                            <FacebookIcon style={{ color: 'blue', fontSize: '28px' }} />
                        </IconButton>
                        <IconButton onClick={() => window.open('https://twitter.com', '_blank')}>
                            <YouTubeIcon style={{ color: 'red', fontSize: '28px' }} />
                        </IconButton>
                        <IconButton onClick={() => window.open('https://instagram.com', '_blank')}>
                            <InstagramIcon style={{ color: 'red', fontSize: '28px' }} />
                        </IconButton>
                    </div>
                </div>
            </div>
        </Drawer>

    );
};

export default SideBar;
