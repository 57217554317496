import React, { useEffect, useRef, useState } from 'react';
import './Features.css';
import EInvoice from '../../assets/images/Fetaures/EInvoice.png';
import EInvoiceDark from '../../assets/images/Fetaures/EInvoice-Dark.png';

import EWayBill from '../../assets/images/Fetaures/E-Way.png';
import EWayBillDark from '../../assets/images/Fetaures/E-Way-Dark.png';
import ItemGrouping from '../../assets/images/Fetaures/ItemGroup.png';
import ItemGroupingDark from '../../assets/images/Fetaures/ItemGroupDark.png';
import Hash from '../../assets/images/Fetaures/Hash.png';
import HashDark from '../../assets/images/Fetaures/HashDark.png';

import Unit from '../../assets/images/Fetaures/Unit.png';
import UnitDark from '../../assets/images/Fetaures/UnitDark.png';

import MultiUser from '../../assets/images/Fetaures/MultiUser.png';
import MultiUserDark from '../../assets/images/Fetaures/MultiUserDark.png';

import CustomerRate from '../../assets/images/Fetaures/CustomerRate.png';
import CustomerRateDark from '../../assets/images/Fetaures/CustomerRateDark.png';

import Convert from '../../assets/images/Fetaures/DocumentExchange.png';

import ConvertDark from '../../assets/images/Fetaures/DocumentExchangeDark.png';
import Secure from '../../assets/images/Fetaures/Secure.png'
import SecureDark from '../../assets/images/Fetaures/secureDark.png';

import Clients from '../../assets/images/Fetaures/Client.png';
import ClientsDark from '../../assets/images/Fetaures/ClientDark.png';
import VideoDialog from '../UI/Controls/Video/VideoDialog';

import MultiCompany from '../../assets/images/Fetaures/MultiCompany.png';
import MultiCompanyDark from '../../assets/images/Fetaures/MultiCompnayDark.png';

import MultipleLocation from '../../assets/images/Fetaures/MultipleLocation.png';
import MultipleLocationDark from '../../assets/images/Fetaures/MultipleLocationDark.png';

import Share from '../../assets/images/Fetaures/Share.png';
import ShareDark from '../../assets/images/Fetaures/Share-Dark.png';

const Card = ({ feature, darkMode, ref }) => {
    const cardRef = useRef(null);
    const [openVideo, setOpenVideo] = useState(false);

    useEffect(() => {
        const card = cardRef.current;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-visible');
                } else {
                    entry.target.classList.remove('is-visible');
                }
            });
        }, {
            threshold: 0.1
        });

        if (card) {
            observer.observe(card);
        }

        return () => {
            if (card) {
                observer.unobserve(card);
            }
        };
    }, []);


    const getImagePath = (imageName) => {
        switch (imageName) {
            case 'EInvoice':
                return <img src={darkMode ? EInvoiceDark : EInvoice} alt={imageName} />
            case 'EWayBill':
                return <img src={darkMode ? EWayBillDark : EWayBill} alt={imageName} />
            case 'ItemGrouping':
                return <img src={darkMode ? ItemGroupingDark : ItemGrouping} alt={imageName} />
            case 'Batch':
                return <img src={darkMode ? HashDark : Hash} alt={imageName} />
            case 'Unit':
                return <img src={darkMode ? UnitDark : Unit} alt={imageName} />
            case 'MultiUser':
                return <img src={darkMode ? MultiUserDark : MultiUser} alt={imageName} />
            case 'CustomerRate':
                return <img src={darkMode ? CustomerRateDark : CustomerRate} alt={imageName} />
            case 'Convert':
                return <img src={darkMode ? ConvertDark : Convert} alt={imageName} />
            case 'Secure':
                return <img src={darkMode ? SecureDark : Secure} alt={imageName} />
            case 'Clients':
                return <img src={darkMode ? ClientsDark : Clients} alt={imageName} />
            case 'MultiCompany':
                return <img src={darkMode ? MultiCompanyDark : MultiCompany} alt={imageName} />
            case 'MultipleLocation':
                return <img src={darkMode ? MultipleLocationDark : MultipleLocation} alt={imageName} />
                case 'Share':
                return <img src={darkMode ? ShareDark : Share} alt={imageName} />
            default:
                break;
        }
    }

    const openVideoHandler = () => {
        setOpenVideo(true);
    }

    const closeVideoHabdler = () => {
        setOpenVideo(false);
    }

    return (
        <>
            <div onClick={() => openVideoHandler()} ref={cardRef} className={`card-wrap ${darkMode ? 'dark' : 'light'}`}>
                <div className={`card-header`}>
                    <span className='card-icon'>
                        {getImagePath(feature.iconName)}
                    </span>
                    <h1 className="card-title">{feature.title}</h1>
                </div>
                <div className="card-content">
                    <p className="card-text">{feature.description}</p>
                </div>
            </div>

            <VideoDialog open={openVideo} onClose={closeVideoHabdler} videoUrl={feature.videoLink} />
        </>
    );
};

export default Card;
