import React from 'react';
import './BadgeGrid.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EInvoice from '../../assets/images/Fetaures/EInvoice.png';
import EwayBill from '../../assets/images/Fetaures/E-Way.png';
import GSTR1 from '../../assets/images/Fetaures/Percentage.png';
import Inventory from '../../assets/images/Fetaures/Inventory.png';
import Share from '../../assets/images/Fetaures/Share.png';
import { useMediaQuery } from '@mui/material';
import EInvoiceDark from '../../assets/images/Fetaures/EInvoice-Dark.png';
import EWwayBillDark from '../../assets/images/Fetaures/E-Way-Dark.png';
import GSTRDark from '../../assets/images/Fetaures/Percentage-Dark.png';
import InventoryDark from '../../assets/images/Fetaures/Inventory-Dark.png';
import ShareDark from '../../assets/images/Fetaures/Share-Dark.png';

const BadgeGrid = ({ darkMode }) => {
    const isMobile = useMediaQuery('(max-width: 1024px)');

    const badges = [
        { id: 1, color: 'gray', text: 'Generate E-Invoices', backgroundImage: darkMode ? EInvoiceDark : EInvoice, summary: 'Seamlessly Generate IRN/QR Code in a single click.' },
        { id: 2, color: 'blue', text: 'Generate E-Way Bills', backgroundImage: darkMode ? EWwayBillDark : EwayBill, summary: 'Save time with effortless E-Way Bill Integration.' },
        { id: 3, color: 'gray', text: 'File Error-Free GSTR-1', backgroundImage: darkMode ? GSTRDark : GSTR1, summary: 'Direct integration with GSTR portal for a smooth experience.' },
        { id: 4, color: 'purple', text: 'Manage Perfect Inventory', backgroundImage: darkMode ? InventoryDark : Inventory, summary: 'Track items with Batch & Serial No., Manage Multiple Warehouses.' },
        { id: 5, color: 'blue', text: 'Share on WhatsApp', backgroundImage: darkMode ? ShareDark : Share, summary: 'Share invoices, statements, etc., via WhatsApp/SMS/Email' }
    ];

    const responsive = {
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2,
        }
    };

    return (
        <>
            {isMobile ? (
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    arrows={false}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}

                >
                    {badges.map(badge => (
                        <div key={badge.id} className="carousel-item">
                            <div className={`badge-icon badge-${badge.color}`}>
                                <img src={badge.backgroundImage} style={{ width: '50%' }} alt='' />
                            </div>
                            <p className="badge-text">{badge.text}</p>
                            {/*    <p className="badge-summary">{badge.summary}</p> */}
                        </div>
                    ))}
                </Carousel>
            ) : (
                <div className={`badge-grid ${darkMode ? 'dark' : 'light'}`}>
                    {badges.map((badge) => (
                        <div key={badge.id} className="badge-item">
                            <div className={`badge-icon badge-${badge.color}`}>
                                <img src={badge.backgroundImage} style={{ width: '50%' }} alt='' />
                            </div>
                            <p className="badge-text">{badge.text}</p>
                            <p className="badge-summary">{badge.summary}</p>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default BadgeGrid;
