import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VideoDialog = ({ open, onClose, videoUrl }) => {

    const defaultVideoUrl = 'https://www.youtube.com/embed/GTioH4ER4L8?si=nA-yeFpBBgdvheme';

    const videoSrc = videoUrl && videoUrl !== "" ? `${videoUrl}?autoplay=1&mute=1` : `${defaultVideoUrl}?autoplay=1&mute=1`;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(10px)',
                },
            }}
            PaperProps={{
                style: { position: 'relative', overflow: 'hidden', background: '#000' }
            }}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: '-9px',
                    top: '-7px',
                    zIndex: 1,
                    color: '#fff',
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent sx={{ padding: 2 }}>
                <iframe
                    width="100%"
                    height="370px"
                    src={videoSrc}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            </DialogContent>

        </Dialog>
    );
};

export default VideoDialog;
