import React from 'react';
import './HeadingText.css';

const HeadingText = ({ darkMode, mainHeading, subHeading }) => {
    return (
        <div className={`feature-heading ${darkMode ? 'dark' : 'light'}`}>
            <h1 className="heading">{mainHeading}</h1>
            <h2 className="subheading">{subHeading}</h2>
            <div className="underline" />
        </div>
    );
};

export default HeadingText;
