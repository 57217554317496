import React, { useState } from 'react';
import './ChatBoat.css';
import WhatsappContainer from './ChatBoat'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const ChatBoatContainer = ({ scrollToSection }) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showchatCount, setShowChatCount] = React.useState(false);
    const openWhatSappContainer = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    }
    const closeWhatSappContainer = () => {
        setOpen(false);
    }
    setInterval(() => {
        setShowChatCount(true)
    }, 4000);

    const getCurrentTime = () => {
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();

        const formattedTime = `${padZero(hours)}.${padZero(minutes)}`;

        return formattedTime;
    };

    const padZero = (num) => (num < 10 ? '0' + num : num);
    const currentTime = getCurrentTime();
    const openPoover = Boolean(anchorEl);
    const id = openPoover ? 'simple-popover' : undefined;
    return (
        <div>
            <WhatsappContainer scrollToSection={scrollToSection} style={{ zIndex: 1000 }} onOpen={open} onClose={closeWhatSappContainer} anchorEl={anchorEl} id={id} getCurrentTime={currentTime} />

            <a className="whatsapp-btn" rel='noreferrer' onClick={openWhatSappContainer} style={{ zIndex: open ? -1 : null }}>

                {showchatCount ? <span className='chat-count'>1</span> : null}  <WhatsAppIcon style={{ color: '#fff', fontSize: "37px" }} />
            </a>
        </div>
    );
}

export default ChatBoatContainer;
