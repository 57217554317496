import React, { useState } from 'react';
import { Grid } from '@mui/material';
import './NewsLetter.css'; // Optional for styles

const NewsLetterButton = ({ darkMode, setOpenDialog, setMobileNumber }) => {
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState('');

    const validateMobile = (value) => {
        const mobilePattern = /^\d{10}$/;
        return mobilePattern.test(value);
    };

    const handleMobileChange = (e) => {
        const value = e.target.value;
        setMobile(value);

        if (!validateMobile(value)) {
            setError('Please enter a valid 10-digit mobile number');
        } else {
            setError('');
        }

        setMobileNumber(value);
    };

    const handleRequestDemo = () => {
        if (validateMobile(mobile)) {
            setOpenDialog(true);
        } else {
            setError('Please enter a valid 10-digit mobile number');
        }
    };

    return (
        <Grid className={`newsletter-container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <form className="newsletter" noValidate autoComplete="off">
                <div className="country-code-container">
                    <img 
                        src="https://pngpix.com/images/hd/india-national-flag-graphic-l0uea67k2d495e3u.jpg" 
                        alt="India Flag"
                        className="flag-icon"
                    />
                    <span className="country-code">+91</span>
                </div>
                <input
                    type="text"
                    placeholder="Mobile Number"
                    value={mobile}
                    onChange={handleMobileChange}
                />
                <button type="button" onClick={handleRequestDemo}>Request Demo</button>
            </form>
            {error && <p className="error-message">{error}</p>} {/* Error message display */}
        </Grid>
    );
};

export default NewsLetterButton;
