import config from "../../config";

export const createLead = async (values) => {
    try {
        let request = {
            id: 0,
            assignedTo: 0,
            name: values?.name,
            sourceId: config.SOURCE_WEBSITE,
            statusId: values.statusId,
            stateId: 0,
            businessTypeId: 403,
            actionDate: new Date().toISOString().slice(0, 10),
            active: true,
            address: {
                id: 0,
                description: "",
                addressLine1: "",
                addressLine2: "",
                addressType: 'Leads',
                isPrimary: true,
                stateId: 0,
                city: '',
                pinCode: "",
                contactPerson: '',
                contactEmail: '',
                contactPhone: values?.phoneNumber,
                website: '',
                active: true
            },
            leadsHistory: {
                leadsId: 0,
                activityId: 0,
                notes: values.partnerInfo,
                active: true
            }
        }

        const response = await fetch(`${config.SERVER_URL}/Leads/CreateLeads`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "AccessToken": '7CCD8FF1-B719-4C36-8883-3D4C40A92849',     // Server Token
                /* "AccessToken": 'EC0982A7-C8DA-4E2D-B3AC-AFC3FCA3F2F2'  */    // Bishal Local Token
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}
