import { Box } from '@mui/material';
import React from 'react';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
const styles = {
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '-4px',
        button: {
            bg: 'transparent',
            border: '0px solid',
            fontSize: 40,
            cursor: 'pointer',
            px: '2px',
            color: '#BBC7D7',
            transition: 'all 0.25s',
            '&:hover': {
                color: 'text',
            },
            '&:focus': {
                outline: 0,
            },
        },
    },
};
const ButtonGroup = ({ next, previous }) => {
    return (
        <div sx={{ width: '100%' }}>
            <div>
                <Box sx={styles.buttonGroup} className="button__group">
                    <button onClick={previous} style={{ background: 'transparent' }} aria-label="Previous">
                        <WestIcon />
                    </button>
                    <button onClick={next} style={{ background: 'transparent' }} aria-label="Next">
                        <EastIcon />
                    </button>
                </Box>
            </div>
        </div>
    );
}

export default ButtonGroup;