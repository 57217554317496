import React, { forwardRef } from 'react';
import './Features.css';
import { Data } from './Data';
import Card from './Card';
import { Grid } from '@mui/material';
import HeadingText from '../UI/Controls/HeadingText/HeadingText';

export const Features = forwardRef(({ darkMode }, ref) => {
    return (
        <>
            <section id="features">
                <HeadingText darkMode={darkMode} mainHeading={"Amazingly Useful Features"} subHeading={"The features you really need"} />
                <Grid ref={ref} container spacing={3} justifyContent="center" className='feature-main-container'>
                    {Data.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card feature={feature} darkMode={darkMode} />
                        </Grid>
                    ))}
                </Grid>
            </section>
        </>
    );
});
