import React, { useState } from 'react';
import './Banner.css'; // Import the CSS file
import BannerImage from '../../assets/images/ezybill_banner.png';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VideoDialog from '../UI/Controls/Video/VideoDialog';

const Banner = ({ darkMode }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className={`banner-container ${darkMode ? 'dark-mode' : ''}`}>
            <div className="shape shape-left"></div>
            <div className="shape shape-right"></div>
            <div className="shape shape-top"></div>
            <div className="imageWrapper">
                <img
                    src={BannerImage}
                    alt="Banner"
                    className="banner-image"
                />
                <div className="playButtonContainer" onClick={handleOpen}>
                    <PlayCircleIcon className="playButton" />
                </div>
            </div>

            <VideoDialog
                open={open}
                onClose={handleClose}
                videoUrl="https://www.youtube.com/embed/PU1OkmDYN18?si=5JEFf2Z0ro38pyik?autoplay=1"
            />
        </div>


    );
};

export default Banner;
