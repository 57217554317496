import React from 'react';
import { Link } from 'react-router-dom';
import './PrimaryButton.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const PrimaryButton = ({ children, darkMode, handleClick, external = false }) => {

  const onClickHandler = (e) => {
    if (handleClick) {
      e.preventDefault();
      handleClick();
    }
  };

  if (external) {
    return (
      <a
        href='https://www.gst.ezybill.in/signup'
        className={`primary-button ${darkMode ? 'dark-mode' : 'light-mode'}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
        <span className="button-arrow">
          <ArrowRightAltIcon />
        </span>
      </a>
    );
  }

  return (
    <Link
      onClick={onClickHandler}
      className={`primary-button ${darkMode ? 'dark-mode' : 'light-mode'}`}
    >
      {children}
      <span className="button-arrow">
        <ArrowRightAltIcon />
      </span>
    </Link>
  );
};

export default PrimaryButton;
