let config = {
    "SOURCE_WEBSITE": 1350,
    "STATUS_NEW": 186,
    "STATUS_PARTNER_ENQUIRY": 544,
    "STATUS_DISTRIBUTOR": 544,
    "SERVER_URL": "https://ganitapps.azurewebsites.net/api",
    "SERVER_URL_LOCAL_HOST": "http://localhost/RestAPI/api",
    "COUPON_CODE": "EZYBILL50",
    "COUPON_START_DATE": "2024-11-14",
    "COUPON_END_DATE": "2024-11-16",
    "OFFER_DESCRIPTION": "50% OFF"
}

export default config;